import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-awesome-styled-grid';

import { SectionRow } from '../Rows';
import SimpleInnerSection from '../SimpleInnerSection';

const HomePageEcosystemSection = () => {
  const { data } = useStaticQuery(query);
  const {
    ecosystemSection: { title, description, diagram }
  } = data.edges[0].node;

  return (
    <SectionRow justify="center">
      <Col lg={6}>
        <SimpleInnerSection
          title={title}
          text={description}
          diagrams={[diagram]}
          maxImageWidths={['610px']}
          captioned
        />
      </Col>
    </SectionRow>
  );
};

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          ecosystemSection {
            title
            description
            diagram {
              image {
                publicURL
              }
              imageAltText
              caption
            }
          }
        }
      }
    }
  }
`;

export default HomePageEcosystemSection;
