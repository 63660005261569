import { GatsbyImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';

import { ImageType } from '../../types/components';
import { SectionRow } from '../Rows';

interface HomePageImageSectionProps {
  image: ImageType;
  imageAltText: string;
  className?: string;
}

const HomePageImageSection: FC<HomePageImageSectionProps> = ({ className, image, imageAltText }) => {
  return (
    <SectionRow justify="center" className={className}>
      <Col lg={8}>
        <GatsbyImage className="full-width-mobile" image={image.childImageSharp.gatsbyImageData} alt={imageAltText} />
      </Col>
    </SectionRow>
  );
};

export default HomePageImageSection;
