import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import Button, { ButtonType } from '../components/Button';
import { SectionInnerRow } from '../components/Rows';
import deviceBreakPoints from '../styles/breakpoints';

interface ButtonsRowProps {
  buttons: ButtonType[];
  centered?: boolean;
  longButtons?: boolean;
  className?: string;
}

const ButtonsRow: FC<ButtonsRowProps> = ({ className, buttons, centered, longButtons }) => {
  return (
    <SectionInnerRowStyled justify={centered ? 'center' : ''} className={className}>
      {buttons.map(({ title, to, type }) => (
        <Col lg={longButtons ? 3 : 2} key={title}>
          <Button to={to} primary={type === 'primary'} simple={type === 'simple'}>
            {title}
          </Button>
        </Col>
      ))}
    </SectionInnerRowStyled>
  );
};

const SectionInnerRowStyled = styled(SectionInnerRow)`
  @media ${deviceBreakPoints.mobile} {
    gap: var(--spacing-4);
  }
`;

export default ButtonsRow;
