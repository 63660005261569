import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { ButtonType } from '../components/Button';
import ButtonsRow from '../components/ButtonsRow';
import CaptionedTitle from '../components/CaptionedTitle';
import IconedContent from '../components/IconedContent';
import { renderIcon } from '../utils/icons';

interface TextGridProps {
  title: string;
  caption: string;
  data: {
    icon: string;
    title: string;
    content: string;
  }[];
  buttons: ButtonType[];
}

const TextGrid: FC<TextGridProps> = ({ title, caption, data, buttons }) => {
  return (
    <>
      <Row>
        <Col lg={5}>
          <CaptionedTitle as="h2" caption={caption}>
            {title}
          </CaptionedTitle>
        </Col>
        <Col lg={6} offset={{ lg: 1 }}>
          <Row>
            {data.map(({ icon, title, content }) => (
              <GridItem key={title} md={4}>
                <IconedContent icon={renderIcon(icon)} title={title}>
                  {content}
                </IconedContent>
              </GridItem>
            ))}
          </Row>
        </Col>
      </Row>
      <ButtonsRow centered buttons={buttons} />
    </>
  );
};

const GridItem = styled(Col)`
  margin-bottom: var(--spacing-4);
`;

export default TextGrid;
