import { FC, useState } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import IconDropdownArrow from '../images/svgs/dropdown-arrow.svg';
import deviceBreakPoints from '../styles/breakpoints';
import { SectionInnerRow } from './Rows';

interface FAQEntryProps {
  question: string;
  answer: string;
  className?: string;
}

const FAQ: FC<{ className?: string; entries: FAQEntryProps[] }> = ({ entries, className }) => {
  return (
    <SectionInnerRow className={className}>
      {entries.map(({ question, answer }) => (
        <Col md={8} lg={6} key={question}>
          <FAQEntry question={question} answer={answer} />
        </Col>
      ))}
    </SectionInnerRow>
  );
};

let FAQEntry: FC<FAQEntryProps> = ({ question, answer, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <FAQEntryQuestion className="h4" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <IconDropdownArrowStyled className={isOpen && 'rotate'} />
      </FAQEntryQuestion>
      <FAQEntryAnswer
        style={{ maxHeight: isOpen ? '100vh' : '0', padding: isOpen ? 'var(--spacing-3)' : '0 var(--spacing-3)' }}
      >
        {answer}
      </FAQEntryAnswer>
    </div>
  );
};

FAQEntry = styled(FAQEntry)`
  background-color: ${({ theme }) => theme.bg.secondary};
  margin-bottom: var(--spacing-3);
`;

const FAQEntryQuestion = styled.div`
  cursor: pointer;
  padding: var(--spacing-3);
  display: flex;
  justify-content: space-between;
`;

const FAQEntryAnswer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  padding: 0 var(--spacing-3);
`;

const IconDropdownArrowStyled = styled(IconDropdownArrow)`
  width: 30px;
  height: auto;
  transform: rotate(0);
  transition: transform 0.2s ease-in-out;
  fill: ${({ theme }) => theme.text.primary};

  &.rotate {
    transform: rotate(-180deg);
  }

  @media ${deviceBreakPoints.tablet} {
    margin-left: var(--spacing-3);
  }
`;

export default FAQ;
