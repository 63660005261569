import { graphql, useStaticQuery } from 'gatsby';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import deviceBreakPoints from '../../styles/breakpoints';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import { BlockRow, OrganismRow } from '../Rows';
import SimpleLink from '../SimpleLink';

const HomePageCryptoHubSection = () => {
  const { data } = useStaticQuery(query);
  const {
    cryptoHubSection: { title, content, link, image, imageAltText }
  } = data.edges[0].node;

  return (
    <CryptoHubRow>
      <Col lg={1}>
        <CryptoHubContent>
          <CaptionedTitle as="h2" styledAs="h3">
            {title}
          </CaptionedTitle>
          <BlockRow>
            <Col>
              <CryptoHubDescription content={content} />
            </Col>
          </BlockRow>
          <OrganismRow>
            <Col>
              <SimpleLink to={link.url}>{link.text}</SimpleLink>
            </Col>
          </OrganismRow>
        </CryptoHubContent>
      </Col>
      <Col lg={11}>
        <CryptoHubImageStyled src={image.publicURL} alt={imageAltText} />
      </Col>
    </CryptoHubRow>
  );
};

const CryptoHubImageStyled = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: -1px;
`;

const CryptoHubRow = styled(Row)`
  position: relative;

  @media ${deviceBreakPoints.tablet} {
    gap: var(--spacing-5);
  }
`;

const CryptoHubContent = styled.div`
  position: absolute;
  width: calc(5 / 12 * 100%);

  @media ${deviceBreakPoints.tablet} {
    position: relative;
    width: 100%;
  }
`;

const CryptoHubDescription = styled(Markdown)`
  p {
    margin-bottom: var(--spacing-4);
  }
`;

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          cryptoHubSection {
            title
            content
            link {
              text
              url
            }
            image {
              publicURL
            }
            imageAltText
          }
        }
      }
    }
  }
`;

export default HomePageCryptoHubSection;
