import { graphql, useStaticQuery } from 'gatsby';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import deviceBreakPoints from '../../styles/breakpoints';
import CaptionedTitle from '../CaptionedTitle';
import Diagram from '../Diagram';
import Markdown from '../Markdown';
import { BlockRow, SectionRow } from '../Rows';

const SwapboxVSClassicATM = () => {
  const { data } = useStaticQuery(query);
  const {
    swapboxVSclassicATMSection: { title, description, swapboxDiagram, classicATMDiagram }
  } = data.edges[0].node;

  return (
    <SectionRowStyled>
      <Col lg={3}>
        <CaptionedTitle as="h2" styledAs="h3">
          {title}
        </CaptionedTitle>
        <BlockRow>
          <Col>
            <Markdown content={description} />
          </Col>
        </BlockRow>
      </Col>
      <Col lg={8} offset={{ lg: 1 }}>
        <DiagramsRow>
          <Col lg={6}>
            <Diagram
              image={swapboxDiagram.image}
              header={swapboxDiagram.header}
              imageAltText={swapboxDiagram.imageAltText}
            />
          </Col>
          <Col lg={6}>
            <Diagram
              image={classicATMDiagram.image}
              header={classicATMDiagram.header}
              imageAltText={classicATMDiagram.imageAltText}
            />
          </Col>
        </DiagramsRow>
      </Col>
    </SectionRowStyled>
  );
};

const DiagramsRow = styled(Row)`
  @media ${deviceBreakPoints.tablet} {
    gap: var(--spacing-3);
  }
`;

const SectionRowStyled = styled(SectionRow)`
  @media ${deviceBreakPoints.desktop} {
    gap: var(--spacing-6);
  }
`;

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          swapboxVSclassicATMSection {
            title
            description
            swapboxDiagram {
              header
              image {
                publicURL
              }
              imageAltText
            }
            classicATMDiagram {
              header
              image {
                publicURL
              }
              imageAltText
            }
          }
        }
      }
    }
  }
`;

export default SwapboxVSClassicATM;
