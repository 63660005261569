import { GatsbyImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import styled from 'styled-components';

import { ImageType } from '../types/components';
import BorderedBox from './BorderedBox';
import Caption from './Caption';

export interface TestimonialProps {
  text: string;
  author: string;
  image: ImageType;
  imageAltText: string;
  className?: string;
}

const Testimonial: FC<TestimonialProps> = ({ className, text, author, image, imageAltText }) => {
  return (
    <BorderedBox className={className}>
      <Content>
        <TopContent>
          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={imageAltText} />
          <TextContent>{text}</TextContent>
        </TopContent>
        <BottomContent>
          <CaptionStyled>{author}</CaptionStyled>
        </BottomContent>
      </Content>
    </BorderedBox>
  );
};

const Content = styled.div`
  background-color: var(--color-grey-400);
  padding: var(--spacing-2);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextContent = styled.div`
  margin-top: var(--spacing-4);
`;

const CaptionStyled = styled(Caption)`
  color: var(--color-grey-500);
  margin-top: var(--spacing-2);
`;

const TopContent = styled.div``;

const BottomContent = styled.div``;

export default Testimonial;
