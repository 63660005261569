import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import { OrganismRow, SectionRow } from '../Rows';

interface Image {
  image: {
    publicURL: string;
  };
}

const HomePageDIYSection = () => {
  const { data } = useStaticQuery(query);
  const {
    diySection: { title, description, images }
  } = data.edges[0].node;

  return (
    <>
      <SectionRow justify="center">
        <Col lg={6}>
          <CaptionedTitle as="h2" styledAs="h3">
            {title}
          </CaptionedTitle>
          <OrganismRow>
            <Col>
              <Markdown content={description} />
            </Col>
          </OrganismRow>
        </Col>
      </SectionRow>
      <ImagesSectionRow>
        <ImagesCol>
          {images.map(({ image }: Image, index: number) => (
            <img key={image.publicURL} src={image.publicURL} alt={`DYI image ${index}`} />
          ))}
          <Placeholder />
        </ImagesCol>
      </ImagesSectionRow>
    </>
  );
};

const ImagesCol = styled(Col)`
  flex-direction: row;
  gap: var(--spacing-4);
  align-items: flex-end;
`;

const ImagesSectionRow = styled(SectionRow)`
  overflow-x: scroll;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding-left: calc(((100vw - 100%) / 2));
`;

const Placeholder = styled.div`
  width: calc((100vw - 100%) / 2);
  height: 1px;
  flex-shrink: 0;
`;

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          diySection {
            title
            description
            images {
              image {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePageDIYSection;
