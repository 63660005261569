import { FC } from 'react';
import styled from 'styled-components';

import HexagonIcon from '../images/hexagon.svg';
import HexagonGreyIcon from '../images/hexagon-grey.svg';
import deviceBreakPoints from '../styles/breakpoints';

interface MenuItemProps {
  title: string;
}

interface SideMenuProps {
  menuItems: MenuItemProps[];
  activeMenuItem?: number;
  onMenuItemClick: (index: number) => void;
  disableMenuItemActivation?: boolean;
  className?: string;
}

let SideMenu: FC<SideMenuProps> = ({
  menuItems,
  activeMenuItem,
  onMenuItemClick,
  disableMenuItemActivation,
  className
}) => (
  <ul className={className}>
    {menuItems.map((menuItem: MenuItemProps, index: number) => (
      <MenuItem
        id={`menu-item-${menuItem.title.toLocaleLowerCase().replace(/\s/g, '-')}`}
        key={menuItem.title}
        onClick={() => onMenuItemClick(index)}
        className={!disableMenuItemActivation && activeMenuItem === index ? 'active' : ''}
      >
        <h4>{menuItem.title}</h4>
      </MenuItem>
    ))}
  </ul>
);

SideMenu = styled(SideMenu)`
  list-style: none;
  padding: 0;
  margin: 0;

  @media ${deviceBreakPoints.tablet} {
    display: flex;
    overflow-x: scroll;
    padding-bottom: var(--spacing-5);
  }
`;

const MenuItem = styled.li`
  color: ${({ theme }) => theme.text.secondary};
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: var(--spacing-3);
  }

  @media ${deviceBreakPoints.tablet} {
    flex-shrink: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: var(--spacing-5);
      margin-bottom: 0;
    }
  }

  &:before {
    position: absolute;
    left: calc((18px + var(--spacing-2)) * -1);
    height: 20px;
  }

  &.active {
    color: ${({ theme }) => theme.text.primary};

    &:before {
      content: url(${HexagonIcon});
    }
  }

  &.active ~ & {
    color: ${({ theme }) => theme.text.secondary};

    &:before {
      content: '';
    }
  }

  &:hover:not(.active):before {
    content: url(${HexagonGreyIcon});
    display: block;
  }

  h4 {
    margin: 0;
  }
`;

export default SideMenu;
