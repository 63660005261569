import { graphql, useStaticQuery } from 'gatsby';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import ATMImage from '../../images/svgs/atm.svg';
import deviceBreakPoints from '../../styles/breakpoints';
import CaptionedTitle from '../CaptionedTitle';
import Heading from '../Heading';
import Markdown from '../Markdown';
import PageHeader from '../PageHeader';
import { OrganismRow, SectionInnerRow, SectionRow } from '../Rows';
import HomePageATMScrollImage from './HomePageATMScrollImage';

const HomePageHeader = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption, definition, differencesSection }
  } = data.edges[0].node;

  return (
    <PageHeader>
      <Row>
        <Col md={4} lg={8}>
          <CaptionedTitle titleSections={title} caption={caption} as="h1" />
          <ATMImageMobileContainer>
            <ATMImageMobile />
          </ATMImageMobileContainer>
          <SectionInnerRow>
            <Col lg={6}>
              <HeaderContentMarkdown content={definition} />
            </Col>
          </SectionInnerRow>
          <SectionRow>
            <Col lg={8}>
              <section>
                <Heading element="h2">{differencesSection.title}</Heading>
                <SectionInnerRow>
                  <Col>
                    {differencesSection.differences.map(
                      ({ title, description }: { title: string; description: string }) => (
                        <HeaderContentIntroSection key={title}>
                          <CaptionedTitle as="h3">{title}</CaptionedTitle>
                          <OrganismRow>
                            <Col>
                              <HeaderContentMarkdown content={description} />
                            </Col>
                          </OrganismRow>
                        </HeaderContentIntroSection>
                      )
                    )}
                  </Col>
                </SectionInnerRow>
              </section>
            </Col>
          </SectionRow>
        </Col>
        <Col md={4} lg={4}>
          <ATMScrollImageStyled />
        </Col>
      </Row>
    </PageHeader>
  );
};

const HeaderContentMarkdown = styled(Markdown)`
  font-size: var(--font-size-4);
  letter-spacing: var(--letter-spacing-2);
`;

const HeaderContentIntroSection = styled.section`
  &:not(:last-child) {
    margin-bottom: var(--spacing-6);
  }
`;

const ATMImageMobileContainer = styled(SectionInnerRow)`
  display: none;
  margin-bottom: var(--spacing-5);

  @media ${deviceBreakPoints.tablet} {
    display: flex;
    justify-content: center;
  }
`;

const ATMImageMobile = styled(ATMImage)`
  height: auto;
`;

const ATMScrollImageStyled = styled(HomePageATMScrollImage)`
  @media ${deviceBreakPoints.tablet} {
    display: none;
  }
`;

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          header {
            title
            caption
            definition
            differencesSection {
              title
              differences {
                title
                description
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePageHeader;
