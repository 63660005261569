import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

import ATMPart1Image from '../../images/svgs/atm-parts/part1.svg';
import ATMPart2Image from '../../images/svgs/atm-parts/part2.svg';
import ATMPart3Image from '../../images/svgs/atm-parts/part3.svg';
import ATMPart4Image from '../../images/svgs/atm-parts/part4.svg';
import ATMPart5Image from '../../images/svgs/atm-parts/part5.svg';
import ATMPart6Image from '../../images/svgs/atm-parts/part6.svg';
import ATMPart7Image from '../../images/svgs/atm-parts/part7.svg';

const backPartElInitialTopPosition = 10;
const frontPartElInitialTopPosition = 104;
const innerFrontPartElInitialTopPosition = 148;
const innerBackPartElInitialTopPosition = 40;
const innerTopPartElInitialTopPosition = 50;

let HomePageATMScrollImage: FC<{ className?: string }> = ({ className }) => {
  const containerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.fonts.ready.then(() => {
      if (containerEl.current) {
        const containerHeight = containerEl.current.clientHeight;
        const bottomPartEl = document.querySelector('.atm-bottom-part') as HTMLElement;
        const backPartEl = document.querySelector('.atm-back-part') as HTMLElement;
        const frontPartEl = document.querySelector('.atm-front-part') as HTMLElement;
        const innerBackEl = document.querySelector('.atm-inner-back-part') as HTMLElement;
        const innerFrontEl = document.querySelector('.atm-inner-front-part') as HTMLElement;
        const innerTopEl = document.querySelector('.atm-inner-top-part') as HTMLElement;

        window.addEventListener('scroll', () => {
          if (bottomPartEl && backPartEl && frontPartEl && innerFrontEl && innerBackEl && innerTopEl) {
            const newPosition = window.scrollY;
            if (newPosition < containerHeight - bottomPartEl.clientHeight) {
              bottomPartEl.style.top = `${newPosition}px`;
              backPartEl.style.top = `${newPosition / 1.2 + backPartElInitialTopPosition}px`;
              frontPartEl.style.top = `${newPosition / 1.2 + frontPartElInitialTopPosition}px`;
              innerFrontEl.style.top = `${newPosition / 1.5 + innerFrontPartElInitialTopPosition}px`;
              innerBackEl.style.top = `${newPosition / 1.5 + innerBackPartElInitialTopPosition}px`;
              innerTopEl.style.top = `${newPosition / 2 + innerTopPartElInitialTopPosition}px`;
            }
          }
        });
      }
    });
  }, []);

  return (
    <div className={className} ref={containerEl}>
      <ATMImageContainer>
        <TopPart />
        <BackPart className="atm-back-part" />
        <FrontPart className="atm-front-part" />
        <InnerTopPart className="atm-inner-top-part" />
        <InnerBackPart className="atm-inner-back-part" />
        <BottomPart className="atm-bottom-part" />
        <InnerFrontPart className="atm-inner-front-part" />
      </ATMImageContainer>
    </div>
  );
};

HomePageATMScrollImage = styled(HomePageATMScrollImage)`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const ATMImageContainer = styled.div`
  position: relative;
  height: 100%;
`;

const TopPart = styled(ATMPart1Image)`
  position: relative;
  z-index: 100;
`;

const BackPart = styled(ATMPart7Image)`
  position: absolute;
  top: ${backPartElInitialTopPosition}px;
  left: 2px;
  z-index: 10;
`;

const FrontPart = styled(ATMPart2Image)`
  position: absolute;
  top: ${frontPartElInitialTopPosition}px;
  left: 2px;
  z-index: 90;
`;

const InnerTopPart = styled(ATMPart5Image)`
  position: absolute;
  top: ${innerTopPartElInitialTopPosition}px;
  left: 50px;
  z-index: 40;
`;

const InnerBackPart = styled(ATMPart3Image)`
  position: absolute;
  left: 10px;
  top: ${innerBackPartElInitialTopPosition}px;
  z-index: 30;
`;

const BottomPart = styled(ATMPart4Image)`
  position: absolute;
  top: -4px;
  left: 5px;
  z-index: 35;
`;

const InnerFrontPart = styled(ATMPart6Image)`
  position: absolute;
  top: ${innerFrontPartElInitialTopPosition}px;
  left: 217px;
  z-index: 38;
`;

export default HomePageATMScrollImage;
