import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';

import { ButtonType } from './Button';
import ButtonsRow from './ButtonsRow';
import CaptionedTitle from './CaptionedTitle';
import Markdown from './Markdown';
import { BlockRow, SectionRow } from './Rows';

interface CenteredSectionProps {
  title: string;
  description?: string;
  buttons: ButtonType[];
  className?: string;
}

const CenteredSection: FC<CenteredSectionProps> = ({ className, title, description, buttons }) => {
  return (
    <SectionRow centeredText className={className}>
      <Col>
        <Row justify="center">
          <Col lg={6}>
            <CaptionedTitle as="h2" styledAs="h3">
              {title}
            </CaptionedTitle>
            {description && (
              <BlockRow>
                <Col>
                  <Markdown content={description} />
                </Col>
              </BlockRow>
            )}
          </Col>
        </Row>
        <ButtonsRow centered buttons={buttons} longButtons />
      </Col>
    </SectionRow>
  );
};

export default CenteredSection;
