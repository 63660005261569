import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import DAOFeaturesGrid from '../DAOFeaturesGrid';
import { BlockRow, SectionRow } from '../Rows';
import SimpleInnerSection from '../SimpleInnerSection';

const HomePageDAOSection = () => {
  const { data } = useStaticQuery(query);
  const {
    daoSection: { title, description }
  } = data.edges[0].node;

  return (
    <SectionRow justify="center">
      <Col lg={6}>
        <SimpleInnerSection title={title} text={description} captioned>
          <BlockRow>
            <Col>
              <DAOFeaturesGridStyled />
            </Col>
          </BlockRow>
        </SimpleInnerSection>
      </Col>
    </SectionRow>
  );
};

const DAOFeaturesGridStyled = styled(DAOFeaturesGrid)`
  @media (max-width: 768px) grid-template-columns: repeat(3, 1fr);
`;

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          daoSection {
            title
            description
          }
        }
      }
    }
  }
`;

export default HomePageDAOSection;
