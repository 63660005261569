import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { SectionInnerRow } from '../components/Rows';
import Testimonial, { TestimonialProps } from '../components/Testimonial';
import deviceBreakPoints from '../styles/breakpoints';

const TestimonialsRow: FC<{ className?: string; testimonials: TestimonialProps[] }> = ({ className, testimonials }) => {
  return (
    <SectionInnerRow className={`scrollable full-width-mobile ${className}`}>
      {testimonials.map((testimonial: TestimonialProps) => (
        <ColStyled xs={3} lg={4} key={testimonial.text}>
          <Testimonial {...testimonial} />
        </ColStyled>
      ))}
    </SectionInnerRow>
  );
};

const ColStyled = styled(Col)`
  @media ${deviceBreakPoints.tablet} {
    min-width: auto;
  }

  @media ${deviceBreakPoints.mobile} {
    min-width: 75%;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

export default TestimonialsRow;
