import { graphql, useStaticQuery } from 'gatsby';
import { Col, Container } from 'react-awesome-styled-grid';
import { ThemeProvider } from 'styled-components';

import CaptionedTitle from '../components/CaptionedTitle';
import CardsRow from '../components/CardsRow';
import CenteredSection from '../components/CenteredSection';
import CommunitiesRow from '../components/CommunitiesRow';
import FAQ from '../components/FAQ';
import HomePageCryptoHubSection from '../components/home-page/HomePageCryptoHubSection';
import HomePageCurrentProblemsSection from '../components/home-page/HomePageCurrentProblemsSection';
import HomePageDAOSection from '../components/home-page/HomePageDAOSection';
import HomePageDIYSection from '../components/home-page/HomePageDIYSection';
import HomePageEcosystemSection from '../components/home-page/HomePageEcosystemSection';
import HomePageHeader from '../components/home-page/HomePageHeader';
import HomePageImageSection from '../components/home-page/HomePageImageSection';
import HomePageSwapboxVSClassicATMSection from '../components/home-page/HomePageSwapboxVSClassicATMSection';
import Page from '../components/Page';
import { BlockRow, SectionRow } from '../components/Rows';
import TestimonialsRow from '../components/TestimonialsRow';
import TextGrid from '../components/TextGrid';
import ThemedBackground from '../components/ThemedBackground';
import { darkTheme, lightTheme } from '../styles/themes';

const IndexPage = () => {
  const { data } = useStaticQuery(query);
  const {
    seo,
    faqSection,
    cardsSection,
    forHostsSection,
    forUsersSection,
    communitySection,
    centeredSection,
    testimonialsSection,
    imageSection
  } = data.edges[0].node;

  return (
    <Page seoTitle={seo.title} seoDescription={seo.description}>
      <ThemeProvider theme={lightTheme}>
        <HomePageHeader />
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ThemedBackground pt pb>
          <Container>
            <TextGrid
              title={forHostsSection.title}
              caption={forHostsSection.caption}
              data={forHostsSection.gridItems}
              buttons={forHostsSection.buttons}
            />
          </Container>
        </ThemedBackground>
      </ThemeProvider>
      <ThemeProvider theme={lightTheme}>
        <ThemedBackground pt pb>
          <Container>
            <HomePageCryptoHubSection />
          </Container>
        </ThemedBackground>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ThemedBackground pt pb>
          <Container>
            <TextGrid
              data={forUsersSection.gridItems}
              buttons={forUsersSection.buttons}
              title={forUsersSection.title}
              caption={forUsersSection.caption}
            />
            {imageSection.image && (
              <HomePageImageSection image={imageSection.image} imageAltText={imageSection.imageAltText} />
            )}
            <HomePageCurrentProblemsSection />
            <HomePageSwapboxVSClassicATMSection />
          </Container>
        </ThemedBackground>
        <ThemeProvider theme={lightTheme}>
          <ThemedBackground pt pb>
            <Container>
              <HomePageDIYSection />
              <SectionRow>
                <Col>
                  <CaptionedTitle as="h2" styledAs="h3">
                    {testimonialsSection.title}
                  </CaptionedTitle>
                  <TestimonialsRow testimonials={testimonialsSection.testimonials} />
                </Col>
              </SectionRow>
              <CenteredSection {...centeredSection} />
              <SectionRow>
                <Col>
                  <CaptionedTitle as="h2" styledAs="h3">
                    {cardsSection.title}
                  </CaptionedTitle>
                  <BlockRow>
                    <Col>
                      <CardsRow cards={cardsSection.cards} />
                    </Col>
                  </BlockRow>
                </Col>
              </SectionRow>
            </Container>
          </ThemedBackground>
        </ThemeProvider>
        <ThemeProvider theme={darkTheme}>
          <ThemedBackground pt pb>
            <Container>
              <HomePageEcosystemSection />
              <HomePageDAOSection />
              <SectionRow>
                <Col>
                  <CaptionedTitle as="h2" styledAs="h3">
                    {faqSection.title}
                  </CaptionedTitle>
                  <FAQ entries={faqSection.entries} />
                </Col>
              </SectionRow>
              <SectionRow>
                <Col>
                  <CaptionedTitle as="h2" styledAs="h3">
                    {communitySection.title}
                  </CaptionedTitle>
                  <CommunitiesRow />
                </Col>
              </SectionRow>
            </Container>
          </ThemedBackground>
        </ThemeProvider>
      </ThemeProvider>
    </Page>
  );
};

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          seo {
            title
            description
          }
          forHostsSection {
            title
            caption
            gridItems {
              title
              icon
              content
            }
            buttons {
              title
              to
              type
            }
          }
          forUsersSection {
            title
            caption
            gridItems {
              title
              icon
              content
            }
            buttons {
              title
              to
              type
            }
          }
          imageSection {
            image {
              childImageSharp {
                gatsbyImageData(height: 613, placeholder: BLURRED, formats: [AUTO])
              }
            }
            imageAltText
          }
          testimonialsSection {
            title
            testimonials {
              text
              author
              image {
                childImageSharp {
                  gatsbyImageData(height: 26, placeholder: BLURRED, formats: [AUTO])
                }
              }
              imageAltText
            }
          }
          centeredSection {
            title
            description
            buttons {
              title
              to
              type
            }
          }
          faqSection {
            title
            entries {
              question
              answer
            }
          }
          cardsSection {
            title
            cards {
              title
              description
              icon
              link {
                text
                url
              }
            }
          }
          communitySection {
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
