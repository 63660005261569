import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import DownLongArrowIcon from '../../images/svgs/down-long-arrow.svg';
import ButtonsRow from '../ButtonsRow';
import CaptionedTitle from '../CaptionedTitle';
import { BlockRow, SectionInnerRow, SectionRow } from '../Rows';
import SideMenu from '../SideMenu';

const HomePageCurrentProblemsSection = () => {
  const { data } = useStaticQuery(query);
  const {
    currentProblemsSection: { title, tabs, image, imageAltText, buttons }
  } = data.edges[0].node;

  const [activeTab, setActiveTab] = useState(0);

  return (
    <SectionRow>
      <Col>
        <SectionInnerRow justify="center">
          <Col lg={8}>
            <CaptionedTitle as="h2" styledAs="h3">
              {title}
            </CaptionedTitle>
            <BlockRow>
              <Col md={3} lg={6} offset={{ md: 1, lg: 0 }}>
                <SideMenu menuItems={tabs} onMenuItemClick={setActiveTab} activeMenuItem={activeTab} />
              </Col>
              <Col md={4} lg={6}>
                <Row>
                  <Col xs={1} lg={3}>
                    <ProblemToSolution>
                      <Label>Problem</Label>
                      <DownLongArrowIconStyled />
                      <Label>Solution</Label>
                    </ProblemToSolution>
                  </Col>
                  <Col xs={3} lg={9}>
                    <Problem>{tabs[activeTab].content.problem}</Problem>
                    <Solution>{tabs[activeTab].content.solution}</Solution>
                  </Col>
                </Row>
              </Col>
            </BlockRow>
          </Col>
        </SectionInnerRow>
        <SectionInnerRow>
          <Col>
            <Row justify="center">
              <Col lg={8}>
                <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={imageAltText} />
              </Col>
            </Row>
            <ButtonsRow centered buttons={buttons} />
          </Col>
        </SectionInnerRow>
      </Col>
    </SectionRow>
  );
};

const Label = styled.div`
  color: ${({ theme }) => theme.text.secondary};
`;

const ProblemToSolution = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DownLongArrowIconStyled = styled(DownLongArrowIcon)`
  margin: var(--spacing-1) 0;
`;

const Problem = styled.div`
  margin-bottom: var(--spacing-5);
`;
const Solution = styled.div``;

const query = graphql`
  query {
    data: allHomePageYaml {
      edges {
        node {
          currentProblemsSection {
            title
            tabs {
              title
              content {
                problem
                solution
              }
            }
            image {
              childImageSharp {
                gatsbyImageData(height: 613, placeholder: BLURRED, formats: [AUTO])
              }
            }
            imageAltText
            buttons {
              title
              to
              type
            }
          }
        }
      }
    }
  }
`;

export default HomePageCurrentProblemsSection;
