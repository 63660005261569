import { FC } from 'react';
import styled from 'styled-components';

interface IconedContentProps {
  icon: JSX.Element | null;
  title: string;
  className?: string;
}

let IconedContent: FC<IconedContentProps> = ({ icon, title, children, className }) => (
  <section className={className}>
    <header>
      {icon}
      <Title className="p">{title}</Title>
    </header>
    <Content>{children}</Content>
  </section>
);

IconedContent = styled(IconedContent)`
  svg path {
    stroke: ${({ theme }) => theme.text.primary};
  }
`;

const Title = styled.h3`
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
`;

const Content = styled.p`
  color: ${({ theme }) => theme.text.secondary};
`;

export default IconedContent;
